import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import DashboardPage from "../views/DashboardPage.vue"
import PublishPage from "../views/PublishPage.vue";
import Loops from "../views/Loops.vue";
import Settings from "../views/Settings.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/Settings",
    name: "Settings",
    component: Settings
  },
  {
    path: '/Publier',
    name: 'Publier',
    component: PublishPage
  },
  {
    path: "/Dashboard",
    name: "Dashboard",
    component: DashboardPage,
  },
  {
    path: "/loops",
    name: "Loops",
    component: Loops,
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
