<template>
  <v-dialog v-model="active" :max-width="popupWidth">
    <template v-slot:activator="{ on , attrs }">
      <slot name="button" v-bind="{ on, attrs }"></slot>
    </template>
    <v-card dark class="details-card">
      <v-icon
        fixed
        right
        class="ma-1 close-dialog"
        x-large
        dark
        @click="active = false"
      >
        mdi-close
      </v-icon>
      <v-row :style="headerStyle" class="ma-0">
        <v-col cols="3" class="my-auto" v-if="$vuetify.breakpoint.smAndUp">
          <v-img class="rounded-circle" :src="song.imagePath"></v-img>
        </v-col>
        <v-col>
          <v-card-title>
            <h2>{{ song.title }}</h2>
          </v-card-title>
          <v-card-subtitle class="beatmaker">
            <!-- //TODO: Provide link to beatmaker profile page -->
            <router-link to="beatmaker">
              <v-avatar size="36px">
                <img :src="beatmakerAvatar" alt="avatar">
              </v-avatar>
              {{ song.beatmaker }}
            </router-link>
          </v-card-subtitle>
          <v-chip outlined class="ma-1">{{ song.genre }}</v-chip><br>
          <v-chip outlined class="ma-1">{{ song.licence }}</v-chip>
          <v-chip outlined class="ma-1">{{ `${song.BPM} BPM` }}</v-chip><br>
          <v-chip outlined class="ma-1">{{ `${song.nbListens} écoutes` }}</v-chip>
          <v-chip outlined class="ma-1">{{ `${song.nbDownload} téléchargements` }}</v-chip>
        </v-col>
      </v-row>
      <v-tabs v-model="selectedTab">
        <v-tab v-for="tab in tabs" :key="tab">
          {{ tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <v-card v-if="selectedTab === 0" dark>
          <v-text-field
            v-model="newComment"
            label="Écrire un commentaire..."
            solo
            filled
            outlined
            clearable
            @keydown.enter="submitComment"
            class="mx-4"
          >
          </v-text-field>
          <v-list three-line>
            <v-list-item
              v-for="(comment, index) in song.submission.comments"
              :key="index"
            >
                <v-list-item-avatar>
                  <!-- //TODO: Provide link to beatmaker profile page -->
                  <router-link to="beatmaker" class="commenterLink">
                      <v-list-item-avatar><v-img :src="commenterAvatars[index]"></v-img></v-list-item-avatar>
                  </router-link>
                </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <!-- //TODO: Provide link to beatmaker profile page -->
                  <router-link to="beatmaker" class="commenterLink">
                    <h4>{{ comment.author }}</h4>
                  </router-link>
                </v-list-item-title>
                {{ comment.text }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <p class="mx-4" v-if="!song.submission.comments.length">Il n'y a pas (encore) de commentaires pour ce son.</p>
        </v-card>
        <v-card v-else dark>
          <v-container>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  label="Identité musicale"
                  :value="song.beatmaker"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  label="Featuring"
                  :value="song.beatmaker"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  label="Social URL"
                  :value="song.beatmakerPath"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  label="Adresse email"
                  :value="song.beatmakerMail"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  label="Utilisation de samples"
                  :value="song.samples ? 'Oui' : 'Non'"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  label="Chaîne de publication souhaitée"
                  :value="song.submission.channel"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <p class="mx-3">
            Cliquez pour <a :href="song.downloadLinks.wav">télécharger {{song.title}}</a> (WAV)<br>
            Cliquez pour <a :href="song.downloadLinks.mp3">télécharger {{song.title}}</a> (MP3)<br>
          </p>
          <v-divider/>
          <v-container v-if="song.submission.status === 'pending'">
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="selectedChannel"
                  :items="channels"
                  outlined
                ></v-select>
                <v-btn
                  @click="acceptSubmission"
                  width="100%"
                  color="success"
                  >Valider</v-btn>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="submissionComment"
                  label="Raison du refus"
                  outlined
                ></v-text-field>
                <v-btn
                  @click="rejectSubmission"
                  width="100%"
                  color="error"
                >Refuser</v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-else>
            <v-chip
              v-if="song.submission.status === 'approved'"
              outlined
              color="success"
              class="ma-1"
            >Demande validée
            </v-chip>
            <v-chip
              v-else
              outlined
              color="error"
              class="ma-1"
            >Demande rejetée
            </v-chip>
            <v-chip outlined class="ma-1">{{ song.submission.channel }}</v-chip>
          </v-container>
        </v-card>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    song: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      active: false,
      tabs: [
        "Commentaires",
      ],
      selectedTab: 0,
      newComment: "",
      submissionComment: "",
      channels: [
        {
          text: "InstruRap",
          value: "InstruRap",
        },
        {
          text: "InstruLab",
          value: "InstruLab",
        },
        {
          text: "InstruReggae",
          value: "InstruReggae",
        },
        {
          text: "InstruLofi",
          value: "InstruLofi",
        },
      ],
      selectedChannel: "InstruRap",
      //TODO: Sync data with back
      userIsAdmin: true,
      beatmakerAvatar: "https://scontent-cdg2-1.xx.fbcdn.net/v/t1.6435-9/153933282_163171788949156_8881152839970675297_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=D1F1xsfhGxsAX-7bZbo&tn=SaXoNOS49l10eWdL&_nc_ht=scontent-cdg2-1.xx&oh=0de03bcdcdcc8e2d8b3f6ec5ac74a60c&oe=614F967A",
      commenterAvatars: [
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
        "https://images-na.ssl-images-amazon.com/images/I/51NA4HfQo1L.jpg",
        "https://i.redd.it/7qalrjf53th51.png",
      ],
      };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url("${this.song.imagePath}")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }
    },
    popupWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "90%";
        case "sm":
          return "85%";
        case "md":
          return "65%";
        case "lg":
          return "55%";
        case "xl":
          return "55%";
        default:
          return "55%";
      }
    }
  },
  methods: {
    //TODO: Sync actions with back
    submitComment() {
      this.newComment = "";
    },
    acceptSubmission() {
      console.log(this.selectedChannel);
    },
    rejectSubmission() {
      console.log(this.submissionComment);
      this.submissionComment = "";
    },
  },
  //Adds appropriate tabs and preselects appropriate channel
  created() {
    if (this.song.isSubmission && this.userIsAdmin) {
      this.tabs.push("Gestion demande");
      if (this.song.submission.status === "pending") {
        var index = this.channels.findIndex((channel) => channel.value === this.song.submission.channel);
        this.channels[index].text += " (souhaité)";
        this.selectedChannel = this.channels[index].value;
      };
    };
  },
}
</script>

<style scoped>
.v-dialog__content {
  align-items: flex-start;
}

.close-dialog {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.beatmaker a{
  font-size: 150%;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

.commenterLink {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 600px) {
  .close-dialog {
    position: fixed;
    top: 30px;
    right: 30px;
  }
}
</style>
