<template lang="">
<v-container fluid class="grey darken-1">
    <v-row class="py-2 px-2 mb-3"><v-icon color=white>mdi-cog</v-icon><span class="h1 white--text">Paramètres du compte</span></v-row>
    <v-row class='justify-center'>
        <v-col cols=11 md=9>
            <validation-observer ref="observer" v-slot="{ invalid }">
            <v-row class="justify-center">
                <v-col cols=12 lg=4 class="mx-7">
                    <v-row>
                         <v-col cols="12" class="white rounded-lg mb-7">
                            <v-row class="grey lighten-2 rounded-lg blue--text">
                                <v-col cols=12>Photo de profil</v-col>
                            </v-row>
                            <v-row class="justify-center text-center">
                                <v-col cols="12">
                                    <v-img class="mb-4" height="10em" contain :src="user.imageProfile"></v-img>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col class="mt-5 pb-0">
                                    <validation-provider rules="ext:png,jpg|size:2000" v-slot="{errors}">
                                    <v-file-input :error-messages="errors" class="file" required accept="image/*" v-model="setImageProfile" outlined dense></v-file-input>
                                    </validation-provider>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" class="white rounded-lg mt-7 mb-10">
                            <v-row class="grey lighten-2 rounded-lg blue--text">
                                <v-col cols=12>Photo de couverture</v-col>
                            </v-row>
                            <v-row class="justify-center text-center">
                                <v-col cols="12">
                                    <v-img class="mb-4 fit" height="11em" position="center top" :src="user.imageProfile"></v-img>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col class="mt-5 pb-0">
                                    <validation-provider rules="ext:png,jpg|size:2000" v-slot="{errors}">
                                    <v-file-input :error-messages="errors" required accept="image/*" v-model="setImageCover" outlined dense></v-file-input>
                                    </validation-provider>
                                </v-col>
                                
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" lg=7 class="white rounded-lg mx-7 mb-10">
                    <v-row class="justify-center mb-1">
                        <v-col cols="12" class="grey lighten-2 rounded-lg blue--text">Détails du compte</v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols=12 md=6 class="spacing2">
                            <span class="caption">Nom d'affichage</span>
                            <v-text-field  solo dense outlined v-model="setDisplayName" label="Nom d'affichage"></v-text-field>
                        </v-col>
                        <v-col cols=12 md=6 class='spacing2'>
                            <validation-provider rules="alpha" v-slot="{errors}">
                                <span class="caption">Nom d'utilisateur</span>
                                <v-text-field :error-messages="errors" required solo v-model="setUsername" dense outlined label="Nom d'utilisateur"></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row dense>
                        <v-col class="py-0 mx-0 spacing" cols=12 md=6>
                            <span class="caption">Nom d'utilisateur Instagram</span>
                            <v-text-field  solo dense outlined v-model="setInstagram" label="Nom d'utilisateur Instagram"></v-text-field>
                        </v-col>
                        <v-col class="py-0 mx-0 spacing" cols=12 md=6>
                            <span class="caption">Nom d'utilisateur Snapchat</span>
                            <v-text-field  solo dense outlined v-model="setSnapchat" label="Nom d'utilisateur Snapchat"></v-text-field>
                        </v-col>                        
                        <v-col class="py-0 mx-0 spacing2" cols=12 md=6>
                            <span class="caption">Nom d'utilisateur Twitter</span>
                            <v-text-field  solo dense outlined v-model="setTwitter" label="Nom d'utilisateur Twitter"></v-text-field>
                        </v-col>
                        <v-col class="py-0 mx-0 spacing2" cols=12 md=6>
                            <span class="caption">Nom d'utilisateur Facebook</span>
                            <v-text-field  solo dense outlined v-model="setFacebook" label="Nom d'utilisateur Facebook"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row dense>
                        <v-col class="py-0 mx-0 spacing2" cols=12 md=6>
                            <validation-provider rules="confirmed:confirmation" v-slot="{ errors }">
                                <span class="caption">Mot de passe</span>
                                <v-text-field :error-messages="errors" type="password"  solo dense outlined v-model="setPassword" label="Mot de passe"></v-text-field>                            
                            </validation-provider>
                        </v-col>
                        <v-col class="py-0 mx-0 spacing2" cols=12 md=6>
                            <validation-provider v-slot="{ errors }" vid="confirmation">
                                <span class="caption">Confirmer mot de passe</span>
                                <v-text-field :error-messages="errors" type="password" v-model="confirmation" solo dense outlined label="Confirmer mot de passe"></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row dense>
                        <v-col cols="12" class="py-0 mx-0 spacing">
                            <span class="caption">URL de la chaine Youtube</span>
                            <v-text-field  solo dense outlined v-model="setYoutube" label="youtube.com/c/..."></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0 mx-0 spacing2">
                            <span class="caption">URL du site internet</span>
                            <v-text-field  solo dense outlined v-model="setWebsite" label="https/example.com"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row dense>
                        <v-col cols="12" class="mb-8 spacing2">
                            <validation-provider rules="max:560" v-slot="{ errors }">
                                <span class="caption">Bio du profil</span>
                                <v-textarea :error-messages="errors" :counter="560" rows="2" v-model="setBio" no-resize solo label="Bio à définir" outlined ></v-textarea>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row dense class="justify-center">
                        <v-col cols="12" md=6 class="mt-5 spacing3" :class="btnUpdate">
                            <v-btn v-if="invalid===false" @click="updateProfile" small color="info">Mettre à jour</v-btn>
                            <v-btn v-if="invalid===true" disabled small color="info">Mettre à jour</v-btn>
                        </v-col>
                        <v-col cols="12" md=6 class="mt-5 spacing3" :class="btnDelete">
                            <v-btn small @click="deleteAccount" dark><v-icon color="white">mdi-delete-forever-outline</v-icon> Supprimer le compte</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            </validation-observer>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
import {
	size,
	ext,
    alpha_dash,
    max,
    confirmed,
} from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import VueSimpleAlert from "vue-simple-alert";

extend("ext", {
	...ext,
	message: "Ce format de fichier n'est pas accepté",
});
extend("max", {
	...max,
	message: "La biographie ne doit pas dépasser 560 caractères",
});
extend("alpha", {
	...alpha_dash,
	message: "Les espaces et les caractères spéciaux ne sont pas autorisés",
});
extend("confirmed", {
	...confirmed,
	message: "Les mots de passe ne sont pas identiques",
});
extend("size", {
	...size,
	message: "L'image ne doit pas dépasser 2 MB",
});
export default {
    components: {
		ValidationProvider,
		ValidationObserver,
	},
    created() {
		this.setDisplayName = this.name
        this.setUsername = this.altName
        this.setInstagram = this.instagram
        this.setSnapchat = this.snapchat
        this.setFacebook = this.facebook
        this.setYoutube = this.youtube
        this.setWebsite = this.internet
        this.setTwitter = this.twitter
        this.setPassword = this.password
        this.setBio = this.bio
	},
    methods: {
        // async imageProfileUpload() {
		// 	const imageFileRef = storageRef.child(
		// 		"user/" +
		// 			this.user.id +
		// 			" " +
		// 			"imageProfile"
		// 	);
		// 	await imageFileRef.put(this.setImageProfile);
		// 	this.user.imageProfile = await imageFileRef.getDownloadURL();
		// },
        // async imageCoverUpload() {
		// 	const imageFileRef = storageRef.child(
		// 		"user/" +
		// 			this.user.id +
		// 			"/" +
		// 			"imageCover"
		// 	);
		// 	await imageFileRef.put(this.setImageCover);
		// 	this.user.imageCover = await imageFileRef.getDownloadURL();
		// },
        updateProfile(){
            const settings={
                name:this.user.username,
                bio:this.user.bio,
                password:this.user.password,
                youtube:this.user.youtube,
                twitter:this.user.twitter,
                instagram:this.user.instagram,
                website:this.user.website,
                snapchat:this.user.snapchat,
                profileImage:this.user.imageProfile,
                coverImage:this.user.imageCover,
            }
        },
        deleteAccount(){
            VueSimpleAlert.confirm(
					"La suppression du compte est définitive. Êtes-vous sûr(e) de vouloir supprimer le compte ? ",
					"Attention",
					"warning"
				);
        },
    },
    computed:{
        btnUpdate(){
            switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "text-center";
				case "sm":
					return "text-center";
				case "md":
					return "text-start";
				case "lg":
					return "text-start";
				case "xl":
					return "text-start";
				default:
					return "text-center";
			}
        },
        btnDelete(){
            switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "text-center";
				case "sm":
					return "text-center";
				case "md":
					return "text-end";
				case "lg":
					return "text-end";
				case "xl":
					return "text-end";
				default:
					return "text-center";
			}
        },
    },
    props:{
        name: String,
        altName: String,
        instagram: String,
        snapchat: String,
        facebook: String,
        youtube: String,
        internet: String,
        twitter: String,
        password: String,
        bio: String,
    },
    data() {
        return {
            setDisplayName:"",
            setUsername:"",
            setPassword: "",
            confirmation:"",
            setImageProfile:null,
            setImageCover:null,
            setBio:"",
            setInstagram:"",
            setSnapchat:"",
            setTwitter:"",
            setFacebook:"",
            setYoutube:"",
            setWebsite:"",            
            user:{
                    username:"Green",
                    password: "pass",
                    imageProfile:"https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
                    imageCover:"",
                    bio:"",
                    instagram:"",
                    snapchat:"",
                    twitter:"",
                    facebook:"",
                    youtube:"",
                    website:"",
                }
        }
    },    
}
</script>
<style>
.spacing{
    height: 5em;
}
.spacing2{
    height: 6em;
}
.spacing3{
    height: 3em;
}
</style>