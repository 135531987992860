/* eslint-disable linebreak-style */
<template>
	<v-app>
		<Navbar />
		<v-main class="grey darken-1">
			<router-view />
		</v-main>
	</v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import { db } from "./firebase";
import "firebase/firestore";

export default {
	name: "App",

	components: {
		Navbar,
	},

	data() {
		return {
			users: [],
			// song: {
			//   songID: "657417",
			//   title: "It was only a dream",
			//   beatmaker: "Pylot",
			//   imagePath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
			//   songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
			//   beatmakerPath: "http://iampylot.com/",
			//   beatmakerMail: "example@gmail.com",
			//   samples: true,
			//   isPlaying: false,
			//   nbListens: 111,
			//   nbDownload: 3,
			//   likes: 1,
			//   BPM: 130,
			//   genre: "Rap",
			//   mood: "Dark",
			//   instrument: "Piano",
			//   licence: "Licence non-commerciale",
			//   downloadLinks: {
			//     mp3: "",
			//     wav: "",
			//     tagged: "",
			//   },
			//   comments: [],
			//   isSubmission: true,
			//   submission: {
			//     comments: [
			//       {
			//         author: "Toto77",
			//         text: "Trop bien ton son",
			//       },
			//       {
			//         author: "BeatCritic9000",
			//         text: "Mouais j'ai entendu mieux",
			//       },
			//       {
			//         author: "MecPaumé99",
			//         text: "Je sais pas ce que je fais ici :/ Du coup je vais laisser un commentaire super long pour qu'on puisse voir ce que ça donne. Apparement c'est toujours pas assez long. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi non quis exercitationem culpa nesciunt nihil aut nostrum explicabo reprehenderit optio amet ab temporibus asperiores quasi cupiditate. Voluptatum ducimus voluptates voluptas?",
			//       },
			//     ],
			//     status: 'pending',
			//     channel: "InstruLab",
			//   },
			// },
		};
	},
	firestore() {
		return {
			// users: db.collection("users"),
		};
	},
};
</script>
<style>
@import url("./assets/Style/main.css");
</style>