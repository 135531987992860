<template>
  <v-menu :close-on-content-click="false" dark offset-y max-width="335">
    <template v-slot:activator="{ on, attrs }">
      <div>
        <!-- Possibilite de mettre la props 'content' pour afficher
        DANS le badge le nombre de notif -->
        <v-badge
          bordered
          overlap
          color="red"
          offset-x="27"
          offset-y="22"
          v-show="newNotification"
        >
          <v-btn v-bind="attrs" v-on="on" icon @click="newNotification = false">
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </v-badge>
        <v-btn
          v-show="!newNotification"
          v-bind="attrs"
          v-on="on"
          icon
          @click="newNotification = false"
        >
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </div>
    </template>
    <v-list two-line>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-list two-line>
              <v-card height="25" class="d-flex text-center">
                <!-- A garder pour le moment, peut servir s'ils veulent
            un autre système pour les notifications -->

                <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon small>
                <v-icon class="mb-3 ml-4">mdi-email-mark-as-unread</v-icon>
                </v-btn>
              </template>
                <span>Tout marquer comme lu</span>
            </v-tooltip> -->
                <v-spacer> Notifications</v-spacer>
                <v-btn icon small
                  ><v-icon class="mb-3 mr-4">mdi-close</v-icon></v-btn
                >
              </v-card>
              <template v-for="item in items.slice(0, 6)">
                <v-list-item :key="item.title" :href="item.route">
                  <v-list-item-avatar>
                    <img :src="item.avatar" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <div>
                      <span>
                        {{ item.name }}
                      </span>
                      <span v-text="message(item.type)"> </span>
                    </div>
                    <v-card height="10" class="caption mt-1">
                      <span class="grey--text"
                        >Il y a {{ item.duration }} jour(s).
                        {{ item.signature }}
                      </span>
                    </v-card>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-card
                height="20"
                href="/notification"
                class="justify-center text-center"
              >
                <v-btn text> Voir toutes les notifications</v-btn>
              </v-card>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  // TODO : Sync with firestore database
  methods: {
    /**
     * Computes the number of days the event associated to the notification occured
     *
     * @param {String} timeStamp
     * @returns {Number} the number of days
     */
    duration(timeStamp) {},

    /**
     * Gives the message body associated to the type of notification
     *
     * @param {String} type
     * @return {String} the message body
     *
     */
    message(type) {
      switch (type) {
        case "Communauté":
          return "a commenté votre publication dans l'espace communauté.";
        case "Abonnement":
          return " s'est abonné à votre chaine.";
        case "validation":
          return "a validé la publication de votre beat !";
        case "refus":
          return "n'a pas validé la publication de votre beat. Plus d'information sur le tableau de bord ";
        case "upload":
          return "vient de mettre en ligne un nouveau beat !";
        default:
          return "...";
      }
    },
  },
  data: () => ({
    newNotification: true,
    items: [
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        name: "Kaowbeats",
        id: "12345",
        route: "/",
        duration: "1",
        type: "Communauté",
        signature: "Communauté",
        status: false,
      },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        name: "Wojack",
        id: "12346",
        route: "/tableau",
        duration: "2",
        type: "Abonnement",
        signature: "Abonnement",
        status: false,
      },
      {
        avatar: "",
        name: "L'équipe Instrurap",
        id: "12347",
        route: "/tableau",
        duration: "3",
        signature: "Equipe Instrurap",
        type: "validation",
        status: false,
      },
      {
        avatar: "",
        name: "L'équipe Instrurap",
        id: "12347",
        route: "/tableau",
        duration: "3",
        signature: "Equipe Instrurap",
        type: "refus",
        status: false,
      },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        name: "Wojack",
        id: "12346",
        route: "/songPath",
        duration: "5",
        type: "upload",
        signature: "Abonnement",
        status: false,
      },
    ],
  }),
};
</script>
<style scoped>
.test {
  font-size: 90%;
}
</style>