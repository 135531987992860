<template lang="">
    <v-app-bar app dark class="px-2">
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
          href="./Home.vue"
        />
      </div>
      <v-spacer></v-spacer>
      <v-layout class="d-none d-md-block ml-16" >
          <v-row justify="space-between" class="ml-16">
              <v-menu offset-y open-on-click>
                  <template v-slot:activator="{on, attrs}">
        <v-btn class="ml-16" v-bind="attrs" v-on="on">
            UNIVERS INSTRURAP
        <v-icon>mdi-menu-down</v-icon>
            </v-btn>
                  </template>
                  <v-list>
        <v-list-item
          v-for="(item, social) in social"
          v-bind:key="social.title"

        >
        <v-btn plain grow :href="item.route" target="_blank">
          <v-icon>{{item.icon}}</v-icon>
          {{item.title}}
        </v-btn>

        </v-list-item>

      </v-list>
              </v-menu>
        <v-btn  class="ml-16" href="/Loops">Loops & Drums</v-btn>
        <v-btn  class="ml-16">Communauté</v-btn>
          </v-row>
      </v-layout>
      <v-spacer></v-spacer>
      <v-row class="mr-2 align-center" justify="end" v-if="userIsAuth == true">

      <v-btn plain href="/Publier"><v-icon>mdi-music-box</v-icon></v-btn>
      <div class="mr-5">
      <notifications/>
      </div>
      <userDropdown/>
      </v-row>
      <v-row v-else justify="end">
        <v-btn v-on:click="isAuth()">Se connecter</v-btn>
      </v-row>
<v-app-bar-nav-icon class="d-md-none grey--text ml-2" @click="drawer = !drawer">
</v-app-bar-nav-icon>
    <v-navigation-drawer absolute temporary right v-model="drawer" app  >
<v-list v-if="userIsAuth == true" >
  <v-list-item class="ml-3 px-2" >
    <v-row>
    <v-list-item-action>
      <userDropdown/>
    </v-list-item-action>
    <v-col class="mr-3" >
          <span class="text-center ml-5 overline">{{user.username}} </span>
          <v-btn small v-on:click="isAuth()" >Deconnexion</v-btn>

    </v-col>

    </v-row>
  </v-list-item>
</v-list>
<v-list v-else>
  <v-list-item >
    <v-list-item-action class="mx-8">
      <v-btn large  v-on:click="isAuth()">Se connecter</v-btn>
    </v-list-item-action>
  </v-list-item>
</v-list>

        <v-list>
    <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
        <v-list-item-action>
            <v-icon class="white--text">{{link.icon}} </v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title class="white--text">
                {{link.text}}
            </v-list-item-title>

        </v-list-item-content>

    </v-list-item>
      <v-menu offset-y open-on-click>
      <template v-slot:activator="{on, attrs}">
	<v-list-item v-bind="attrs" v-on="on">
		<v-list-item-action>
			<v-icon>mdi-music-circle</v-icon>
		</v-list-item-action>
		<v-list-item-title>
			Univers Instrurap
			<v-icon>mdi-menu-down</v-icon>
		</v-list-item-title>
	</v-list-item>
</template>
        <v-list>
          <v-list-item
            v-for="(item, social) in social"
            v-bind:key="social.title"
            :href="social.route">
          <v-btn plain grow :href="item.route" target="_blank">
          <v-icon>{{item.icon}}</v-icon>
            {{item.title}}
          </v-btn>

          </v-list-item>
        </v-list>
    </v-menu>

</v-list>
    </v-navigation-drawer>

    </v-app-bar>
</template>
<script>
import userDropdown from "./userDropdown.vue";
import notifications from "./notifications.vue";

export default {
  name: "Navbar",
  components: {
    userDropdown,
    notifications,
  },
  methods: {
    // Log in et out l'utilisateur pour voir les différentes vues
    isAuth() {
      this.userIsAuth = !this.userIsAuth;
    },
  },
  data() {
    return {
      userIsAuth: false,
      // Controle le NavDrawer à droite de l'écran sur petit écran
      drawer: false,
      // Liens de l'espace beatmaker
      // Liens de la Navbar
      links: [
        {
          icon: "mdi-bell-ring",
          text: "Notifications",
          route: "/notifications",
        },
        { icon: "mdi-music-box", text: "Publier", route: "/publier" },
        {
          icon: "mdi-account-music",
          text: "Loops & Drums",
          route: "/loops",
        },
        {
          icon: "mdi-account-supervisor",
          text: "Communauté",
          route: "/communauté",
        },
      ],
      // Info user
      user: {
        username: "KaowBeats",
        avatar: "./avatar-2.png",
        email: "blahblah@hotmail.fr",
        text: "Espace Administrateur",
        EAicon: "mdi-gavel",
        route: "/administateur",
      },
      // Menu déroulant en cliquant sur icone compte
      account: [
        { text: "Mon profil", icon: "mdi-account", route: "/account" },
        {
          text: "Tableau de bord",
          icon: "mdi-clipboard-play-outline",
          route: "/Dashboard",
        },
        {
          text: "Paramètres",
          icon: "mdi-account-cog-outline",
          route: "/settings",
        },
      ],
      // Menu déroulant Univers Instrurap
      social: [
        {
          title: "Youtube",
          icon: "mdi-youtube",
          route: "https://www.youtube.com/channel/UC0seBfmqfs6su72F5Ac90-w",
        },
        {
          title: "Discord",
          icon: "mdi-discord",
          route: "https://discord.com/invite/3rgUn8n",
        },
        {
          title: "Twitter",
          icon: "mdi-twitter",
          route: "https://twitter.com/InstruRapFree",
        },
        {
          title: "Nous contacter",
          icon: "mdi-email",
          route: "/contact",
        },
      ],
    };
  },
};
</script>
<style lang="">
</style>