<template lang="">
<v-container fluid class="grey darken-3">
	<v-row dense class="justify-center">
		<v-col cols="12" md=10 lg=8 >
			<p class='mb-0 mt-4 ml-8'>
				<span class="grey--text text--lighten-2 h1 font-weight-bold head">Populaires</span>
			</p>
		</v-col>
	</v-row>
	<v-row class="justify-center mt-2" @mouseover="btns = true" @mouseleave="btns = false">
    	<vue-horizontal ref="horizontal" class="carousel ml-5">
			<template v-slot:btn-prev>
                <v-btn icon large color="white" class="justify-end mb-16" :class="prevBtn">
                    <v-icon>mdi-less-than</v-icon>
                </v-btn>
        	</template>
			<template v-slot:btn-next>
                <v-btn icon large color="white" class="justify-end mb-16" :class="nextBtn">
                    <v-icon>mdi-greater-than</v-icon>
                </v-btn>
        	</template>
        	<section v-for="song in songCard" :key="song.songID " class="section mx-3">
        		<template lang="">
					<v-col cols="12">
						<v-row>
    						<v-col cols=12>
        						<v-row class='rounded-lg grey darken-4'>
            						<v-col class="py-2 px-2" cols=7>
                						<v-img @dblclick="song.playButton=true" @mouseover="song.playButton = true" @mouseleave="song.playButton = false" height="100%" :src="song.imagePath" class="align-end rounded">
											<v-row>
												<v-col cols="12">
                    								<v-row class=" justify-center">
														<v-card color="transparent" v-if="song.isPlaying === true || song.playButton === true" height="4em" class="pa-3">
															<v-row :class="btnposition">
                        										<v-btn @click="song.isPlaying=false" v-if="song.isPlaying===true" icon>
                            										<div class="icon audio">
                                										<i></i>
                                										<i></i>
                            										</div>
                        										</v-btn>
                        										<v-btn v-if="song.isPlaying===false && song.playButton === true" @click="song.isPlaying=true" icon>
                            									<v-icon x-large fab color="white">mdi-play</v-icon>
                        										</v-btn>
															</v-row>
														</v-card>
                    								</v-row>                   
												</v-col>
												<v-col cols="12">
                    								<v-row>
                        								<v-col cols='12' class='ml-4 mb-2'>
															<v-row >
                            									<v-btn class="ml-2" icon @click.stop.prevent="like(song.songID), song.likes++" v-if="user.liked.indexOf(song.songID) === -1">
                                									<v-icon class="white--text">mdi-thumb-up</v-icon>
                                									<span class="mr-3 mt-1 white--text">{{ song.likes }}</span>
                            									</v-btn>
                            									<v-btn class="ml-2" icon @click.stop.prevent=" removeLike(song.songID), song.likes--" v-else>
                                									<v-icon class="white--text" color="green">mdi-thumb-up</v-icon>
                                									<span class="mr-3 mt-1 white--text caption">{{song.likes}}</span>
                            									</v-btn>
															</v-row>
                        								</v-col>
                    								</v-row>
												</v-col>
											</v-row>
                						</v-img>
            						</v-col>
            						<v-col cols=5>
                						<v-layout column justify-space-between fill-height>
                    						<v-row class='justify-end'>
                        						<v-btn icon class='align-start justify-end mt-1 mr-1'>
                            						<v-icon color='white'>mdi-information-outline</v-icon>
                        						</v-btn>
                    						</v-row>
                    						<v-row class='align-end'>
                       							<v-col>
                            						<v-row class="justify-space-between">
														<v-col cols="12" md="7" xl=6>
															<v-row class='d-none d-md-block justify-center'>
                                								<p class="mb-1 px-3 py-1 rounded green">
                                    								<span class="free h1 white--text">{{song.price}}</span>
                                								</p>
															</v-row>
														</v-col>
														<v-col cols="12" md="5">
															<v-row class="justify-end">
                                								<v-btn icon><v-icon color='white'>mdi-download</v-icon></v-btn>
															</v-row>
														</v-col>
                            						</v-row>
                        						</v-col>
                    						</v-row>
                						</v-layout>
            						</v-col>
        						</v-row>
								<v-row>
									<p class="h1 text ml-2 mt-1">
										<span class="text-uppercase white--text sampleInfo ">{{song.title}}</span>
										<span class="grey--text ml-2 free sampleInfo">{{song.type}}</span> <br>
										<span class="text-uppercase white--text sampleInfo">{{song.beatmaker}}</span>
									</p>
								</v-row>
    						</v-col>
						</v-row>
					</v-col>
				</template>
        	</section>
    	</vue-horizontal>
	</v-row>
</v-container>
</template>
<script>
import VueHorizontal from "vue-horizontal";

export default {
	components: {
		VueHorizontal,
	},
	computed:{
		btnposition(){
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 'mt-3'
				case 'sm':
					return 'mt-2'
				default:
					return ''
			}
		},
		nextBtn(){
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return ''
				case 'sm':
					return 'ml-2'
				case 'md':
					return 'ml-2'
				default:
					return ''
			}
		},
		prevBtn(){
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 'mr-8'
				case 'sm':
					return 'mr-8'
				case 'md':
					return 'mr-16'
				default:
					return 'mr-16'
			}
		}
	},
	methods: {
		like(song) {
			this.user.liked.push(song);
		},
		removeLike(song) {
			this.user.liked.splice(this.user.liked.indexOf(song), 1);
		},
	},
	data() {
		return {
			btns: false,
			user: {
				id: ["id"],
				liked: ["657417"],
			},

			songCard: [
				{
					songID: "657418",
					title: "It was only a dream",
					beatmaker: "Pylot",
					type: "Drumkit",
					imagePath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					isPlaying: false,
					likes: 1,
					playButton: true,
					BPM: 130,
					genre: "Rap",
					mood: "Dark",
					price: "FREE",
					instrument: "Piano",
					licence: "Licence non-commerciale",
				},
				{
					songID: "657419",
					title: "It was only a dream test",
					beatmaker: "Pylot",
					type: "Drumkit",
					imagePath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					isPlaying: false,
					likes: 1,
					playButton: false,
					BPM: 130,
					genre: "Rap",
					mood: "Dark",
					price: "FREE",
					instrument: "Piano",
					licence: "Licence non-commerciale",
				},
				{
					songID: "657459",
					title: "It was only a dream test",
					beatmaker: "Pylot",
					type: "Drumkit",
					imagePath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					isPlaying: false,
					likes: 1,
					playButton: false,
					BPM: 130,
					genre: "Rap",
					mood: "Dark",
					price: "FREE",
					instrument: "Piano",
					licence: "Licence non-commerciale",
				},
				{
					songID: "657420",
					title: "Dream ",
					beatmaker: "Pylot",
					type: "Drumkit",
					imagePath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					isPlaying: false,
					likes: 1,
					playButton: false,
					BPM: 130,
					genre: "Rap",
					mood: "Dark",
					price: "FREE",
					instrument: "Piano",
					licence: "Licence non-commerciale",
				},
				{
					songID: "657445",
					title: "It was only a dream",
					beatmaker: "Pylot",
					type: "Drumkit",
					imagePath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					isPlaying: false,
					likes: 1,
					playButton: false,
					BPM: 130,
					genre: "Rap",
					mood: "Dark",
					price: "FREE",
					instrument: "Piano",
					licence: "Licence non-commerciale",
				},
				{
					songID: "657428",
					title: "It was only a dream test",
					beatmaker: "Pylot",
					type: "Drumkit",
					imagePath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
					songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
					beatmakerPath: "http://iampylot.com/",
					isPlaying: false,
					likes: 1,
					playButton: false,
					BPM: 130,
					genre: "Rap",
					mood: "Dark",
					price: "FREE",
					instrument: "Piano",
					licence: "Licence non-commerciale",
				},
			],
		};
	},
};
</script>
<style>
.free {
	user-select: none;
}
.text {
	font-size: 105%;
}
.head{
	font-size: 200%;
}
@media (max-width: 960px) {
  .text {
    font-size: 90%;
  }
}
section {
	padding: 5px 5px;
}
@media (max-width: 600px) {
	section {
		width: calc((95% - (1px)) / 2);
	}
	  .text {
    font-size: 75%;
  }
	.carousel {
		width: 85%;
	}
	.sampleInfo{
		font-size: 75%;
	}
}
@media (min-width: 600px) {
	section {
		width: calc((85% - (2 * -29px)) / 2);
	}
	.carousel {
		width: 90%;
	}
}

@media (min-width: 960px) {
	section {
		width: calc((90% - (1 * -5px)) / 2);
	}
}

@media (min-width: 1264px) {
	section {
		width: calc((90% - (1 * -18px)) / 3);
	}
	.carousel{
		width: 64%;
	}
}
@media (min-width: 1904px) {
	section {
		width: calc((90% - (3 * -15px)) / 3);
	}
	.carousel{
		width: 64%;
	}
}
.icon {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 16em;
	height: 16em;
	transform: translate(-50%, -50%);
}

/* Audio */

.audio {
	color: #fffffff3;
}

.audio::after,
.audio i,
.audio i::before,
.audio i::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	width: 0.25em;
	height: 0.5em;
	transform: translate(-50%, -50%);
	background: currentColor;
	border-radius: 0.125em;
}

.audio::after {
	animation: audio4 1.35s infinite;
}

.audio i::before {
	left: -0.8125em;
	animation: audio1 1.35s infinite;
}
.audio i::after {
	left: 1.0625em;
	animation: audio3 1.35s infinite;
}

.audio i:first-child {
	margin-left: -1.875em;
	animation: audio2 1.35s infinite;
}

.audio i:last-child {
	margin-left: 1.875em;
	animation: audio6 1.35s infinite;
}

.audio i:last-child::before {
	animation: audio5 1.35s infinite;
}

.audio i:last-child::after {
	animation: audio7 1.35s infinite;
}

@keyframes audio1 {
	9% {
		height: 1.75em;
	}
	36% {
		height: 0.5em;
	}
	42% {
		height: 2.375em;
	}
	69% {
		height: 0.5em;
	}
	76% {
		height: 3.625em;
	}
	100% {
		height: 1em;
	}
}

@keyframes audio2 {
	9% {
		height: 5.5em;
	}
	36% {
		height: 0.5em;
	}
	42% {
		height: 5.5em;
	}
	69% {
		height: 0.5em;
	}
	76% {
		height: 6.75em;
	}
	100% {
		height: 1em;
	}
}

@keyframes audio3 {
	9% {
		height: 6.5em;
	}
	36% {
		height: 0.5em;
	}
	42% {
		height: 3.5em;
	}
	69% {
		height: 0.5em;
	}
	76% {
		height: 3.5em;
	}
	100% {
		height: 1em;
	}
}

@keyframes audio4 {
	9% {
		height: 1.875em;
	}
	36% {
		height: 0.5em;
	}
	42% {
		height: 3.75em;
	}
	69% {
		height: 0.5em;
	}
	76% {
		height: 5.625em;
	}
	100% {
		height: 1em;
	}
}

@keyframes audio5 {
	9% {
		height: 5em;
	}
	36% {
		height: 0.5em;
	}
	42% {
		height: 1.25em;
	}
	69% {
		height: 0.5em;
	}
	76% {
		height: 3.75em;
	}
	100% {
		height: 1em;
	}
}

@keyframes audio6 {
	9% {
		height: 6.125em;
	}
	36% {
		height: 0.5em;
	}
	42% {
		height: 6.875em;
	}
	69% {
		height: 0.5em;
	}
	76% {
		height: 2.5em;
	}
	100% {
		height: 1em;
	}
}

@keyframes audio7 {
	9% {
		height: 3.125em;
	}
	36% {
		height: 0.5em;
	}
	42% {
		height: 1.875em;
	}
	69% {
		height: 0.5em;
	}
	76% {
		height: 3.75em;
	}
	100% {
		height: 1em;
	}
}
</style>