<template lang="">
          <v-menu  dark offset-y left open-on-click>
          <template v-slot:activator="{on, attrs}">
      <v-badge
        bordered
        bottom
        color="green"
        dot
        offset-x="10"
        offset-y="10"

      > <v-responsive>

        <v-avatar v-bind="attrs" v-on="on" size="40">
          <img src="https://cdn.iconscout.com/icon/free/png-256/avatar-366-456318.png">
        </v-avatar>
      </v-responsive>
      </v-badge>
          </template>
            <v-list>
              <v-list-item v-for="items in account" v-bind:key="items.text">
        <v-btn plain grow :href="items.route">
          <v-icon  class="mr-1">{{items.icon}}</v-icon>
          {{items.text}}
        </v-btn>
              </v-list-item>
                <v-list-item>

              <v-btn plain grow v-on:click="isAuth()" >
                <v-icon  class="mr-1">mdi-exit-to-app</v-icon>
                Deconnexion
              </v-btn>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                       <v-btn plain grow :href="user.route">
          <v-icon  class="mr-1">{{user.EAicon}}</v-icon>
          {{user.text}}
        </v-btn>
              </v-list-item>
            </v-list>
        </v-menu>
</template>
<script>
export default {
  name: "userDropdown",
  data() {
    return {
      user: {
        username: "KaowBeats",
        avatar: "./avatar-2.png",
        email: "blahblah@hotmail.fr",

        text: "Espace Administrateur",
        EAicon: "mdi-gavel",
        route: "/administateur",
      },
      account: [
        { text: "Mon profil", icon: "mdi-account", route: "/account" },
        {
          text: "Tableau de bord",
          icon: "mdi-clipboard-play-outline",
          route: "/Dashboard",
        },
        {
          text: "Paramètres",
          icon: "mdi-account-cog-outline",
          route: "/settings",
        },
      ],
    };
  },

};
</script>
<style lang="">

</style>