<template lang="">
<div class="grey darken-1">
    <div class="grey darken-1 mt-2">
        <v-row class='justify-center'>
        <v-col cols="12" md=8>
		<v-row class="justify-end">
		<v-col cols=12 md=8>
        <v-row justify="center">
          <v-col offset-md="2" offset-lg="0" offset-xl='2' cols="11" sm="8" md="10" lg="8" xl="9">
            <v-text-field
              label="Artiste, Titre"
              append-icon="mdi-magnify"
              placeholder="Artiste, Titre"
              dense
			  solo
              v-model="search"
            ></v-text-field>
			<v-row class=" justify-end">
            <v-btn class='white--text mr-1' @click="advanced = !advanced" x-small text >
              Recherche avancée
            </v-btn>
			</v-row>
          </v-col>
        </v-row>
		</v-col>
		</v-row>
        </v-col>
        </v-row>
        <v-expand-transition>
        <v-form v-show='advanced'>
          <v-container>
            <v-row class='justify-center mt-1'>
              <v-col offset-xl="1" cols="11" sm="8" md="11" lg="8" xl="7" class="white rounded-lg">
				  <v-row class="justify-center">
              <v-col cols="12" md="11">
                <span class="h1 titre">
                Recherche avancée
                </span>
                </v-col>
				  </v-row>
                <v-row class="justify-space-around">
                <v-col cols='12'>
					<v-row class="justify-space-around">
						<v-col cols="12" md="5">
                  			<v-text-field   dense  label="Artiste, Titre"
                    			background-color="grey lighten-2"
                  				:height="formHeight"
                  				v-model="search"
                  				placeholder="Artiste, Titre"
                  				solo class= 'justify-center'>
                  			</v-text-field>
						</v-col>
						 <v-col cols="12" md="5">
                  			<v-select
                  			color='white'
                  			solo
                  			dense
                  			v-model="advGenre"
                  			background-color="grey lighten-2"
                  			:height="formHeight" :items="genre"
                  			label="Tous les genres"
                  			placeholder="Tous les genres"
                  			></v-select>
                		</v-col>
					</v-row>
					<v-row class="justify-space-around">
						<v-col cols="12" md="5">
                    		<v-select
                    		color='white'
                    		solo
                    		dense
                    		v-model="advInstru"
                    		background-color="grey lighten-2"
                    		:height="formHeight" :items="instruments"
                    		label="Tous les instruments"
                    		placeholder="Tous les instruments"
                    		></v-select>
                  		</v-col>
						<v-col cols="12" md="5" class="d-flex flex-row justify-space-around">
							<v-row class='justify-space-around'>
								<v-col cols="5" class='bpmin'>
									<v-text-field solo type="number" background-color="grey lighten-2"
									dense label="BPM/Tempo minimum" v-model="advBPMmin" placeholder="BPM/Tempo minimum"></v-text-field>
								</v-col>
								<v-col cols="5" class='bpmax'>
									<v-text-field solo type="number" background-color="grey lighten-2"
									dense label="BPM/Tempo maximum" v-model="advBPMmax" placeholder="BPM/Tempo maximum"></v-text-field>
								</v-col>
							</v-row>
                		</v-col>
					</v-row>
					<v-row class='justify-space-around'>
						<v-col cols='12' md='5'>
							<v-select
								color='white'
								solo
								dense
								background-color="grey lighten-2"
								:height="formHeight" :items="gamme"
								label="Toutes les gammes"
								placeholder="Toutes les gammes"
								v-model="advGamme"
							></v-select>
                		</v-col>
						<v-col cols="5" class='text-center'>
                  			<v-btn dark>Rechercher</v-btn>
                		</v-col>
					</v-row>
                </v-col>
                </v-row>
              </v-col>
                </v-row>
              </v-container>
            </v-form>
        </v-expand-transition>
        <div>
     <SampleDrumCarousel class="my-5"/>
	 <SampleDrumItem/>
    </div> 
    </div>
</div>
</template>
<script>
import SampleDrumItem from "./SampleDrumItem.vue"
import SampleDrumCarousel from "./SampleDrumCarousel.vue"
export default {
	components:{
		SampleDrumItem,
		SampleDrumCarousel,
	},
	computed: {
		// Cette fonction filtre la liste des sons en fonction de l'input de l'utilisateur.
		// Il est aussi possible de filtrer par genre et licence
		// filteredSongs() {
		// 	return this.songItem
		// 		.filter(this.filterBy())
		// 		.sort(this.sortBy(this.advSort, this.ascending));
		// },
		// orderBy() {
		// 	if (this.ascending === true) {
		// 		return "mdi-sort-ascending";
		// 	} else return "mdi-sort-descending";
		// },
		formHeight() {
			return "1em";
		},
	},
	methods: {
		// Cette fonction filtre la liste des sons en fonction des inputs de la recherche avancée
		// filterBy(prop) {
		// 	if (this.advBPMmax != "") {
		// 		return (item) =>
		// 			item.title.toLowerCase().match(this.search.toLowerCase()) &&
		// 			item.genre.toLowerCase().match(this.advGenre.toLowerCase()) &&
		// 			item.licence.toLowerCase().match(this.advLicence.toLowerCase()) &&
		// 			item.BPM >= this.advBPMmin &&
		// 			item.BPM <= this.advBPMmax;
		// 	} else {
		// 		return (item) =>
		// 			item.title.toLowerCase().match(this.search.toLowerCase()) &&
		// 			item.genre.toLowerCase().match(this.advGenre.toLowerCase()) &&
		// 			item.licence.toLowerCase().match(this.advLicence.toLowerCase()) &&
		// 			item.BPM >= this.advBPMmin;
		// 	}
		// },
		// Cette fonction ordonne la liste des sons par ordre alphabétique ou par nombre (vues, like, bpm ..)
		// sortBy(prop, order) {
		// 	if (order === true) {
		// 		if (prop === "Like" || prop === "BPM") {
		// 			return (a, b) =>
		// 				parseInt(a[this.advSort]) < parseInt(b[this.advSort]);
		// 		} else {
		// 			return (a, b) =>
		// 				a[this.advSort.toLowerCase()] < b[this.advSort.toLowerCase()]
		// 					? -1
		// 					: 1;
		// 		}
		// 	} else {
		// 		if (prop === "Like" || prop === "BPM") {
		// 			return (a, b) =>
		// 				parseInt(a[this.advSort]) > parseInt(b[this.advSort]);
		// 		} else {
		// 			return (a, b) =>
		// 				a[this.advSort.toLowerCase()] > b[this.advSort.toLowerCase()]
		// 					? -1
		// 					: 1;
		// 		}
		// 	}
		// },
	},
	watch: {
		// Permet de clear les v-model lors d'une selection par défaut
		advGenre(v) {
			if (v === "Tous les genres") {
				this.$nextTick(() => (this.advGenre = ""));
			}
		},
		advGamme(v) {
			if (v === "Toutes les gammes") {
				this.$nextTick(() => (this.advGamme = ""));
			}
		},
		advInstru(v) {
			if (v === "Tous les instruments") {
				this.$nextTick(() => (this.advInstru = ""));
			}
		},
	},
	data() {
		return {
			advanced: false,
			ascending: true,
			advBPMmin: "",
			advBPMmax: "",
			search: "",
			advInstru: "",
			advGenre: "",
			advGamme: "",
			genre: [
				"Tous les genres",
				"Rap",
				"Trap",
				"Drill",
				"Club",
				"Cloud",
				"Lofi",
				"Reggaeton",
				"R&B/Soul",
				"Dancehall",
				"Grime",
				"Funk",
				"2Step",
				"Pop",
				"Reggae",
				"Rai",
				"Electro",
				"Rock",
				"Autre",
			],
			gamme:  [
				"Am",
				"A#m",
				"A Maj",
				"A# Maj",
				"Bm",
				"B#m",
				"B Maj",
				"B# Maj",
				"Cm",
				"C#m",
				"C Maj",
				"C# Maj",
				"Dm",
				"D#m",
				"D Maj",
				"D# Maj",
				"Em",
				"E#m",
				"E Maj",
				"E# Maj",
				"Fm",
				"F#m",
				"F Maj",
				"F# Maj",
				"Gm",
				"G#m",
				"G Maj",
				"G# Maj",
			],
			instruments: [
				"Tous les instruments",
				"Piano",
				"Guitare",
				"Violon",
				"Flute",
				"Synthé",
				"Voix",
				"Saxo",
				"Tambour",
				"Trompette",
			],
		};
	},
};
</script>
<style>
.bpmin {
	transform: translate(-12px, 0);
}
.bpmax {
	transform: translate(12px, 0);
}
.titre {
	font-size: 150%;
}
</style>