<template lang="">
<div class='grey darken-4'>
    <v-row class="justify-center">
        <v-col>
            <v-row class='justify-end'>
                <v-btn dark class="mt-4 mr-4" @click="abort()">
                    <v-icon>mdi-exit-to-app</v-icon>
                </v-btn>
            </v-row>
            <v-row class="justify-center">
                <v-col cols='8'>
                    <v-row class="justify-start">
                        <p class="pt-12">
                            <v-icon color="grey" large class="mb-4 icon">
                                mdi-arrow-right-thin-circle-outline
                            </v-icon>
                            <span class="white--text titre">
                                Publier mon Sample/Drumkit
                            </span> <br>
                            <span class="grey--text">
                                Cet assistant de publication vous aidera à publier votre projet musical sur le site et/ou notre chaîne YouTube.
                            </span>
                        </p>
                    </v-row>
                </v-col>   
            </v-row>
        </v-col>
    </v-row>
    <v-list class="list justify-center grey lighten-1">
        <v-row class='justify-center'>
            <v-col class="form" cols="10" md='8'>
                <v-list rounded class="grey lighten-2">
                    <v-row class="justify-center">
                        <v-col cols="12">
                            <v-col>
                                <v-row class="grey lighten-2">
                                    <v-col cols="12" md='6' class="py-6" v-for="item in steps" :key="item.number">
                                        <v-row class="justify-center" v-if="page===item.number">
                                            <v-list rounded width="90%"
                                             class="grey darken-4 d-flex flex-row py-6 justify-center">
                                                <v-row class="mt-1 ml-5">
                                                    <v-sheet rounded height="45" width="45" class='text-center'>
                                                        <div class="mt-2">
                                                            <span color='black'>{{item.number}}</span>
                                                        </div>
                                                    </v-sheet>
                                                    <div class="mx-2">
                                                        <p>
                                                            <span class="font-weight-black white--text">{{item.title}}</span> <br>
                                                            <span class="caption font-weight-bold grey--text">{{item.subtitle}}</span>
                                                        </p>
                                                    </div>
                                                </v-row>
                                            </v-list>
                                        </v-row>
                                        <v-row class="justify-center" v-else>
                                            <v-list rounded width="90%"
                                             class="grey lighten-2 d-flex flex-row py-6 justify-center">
                                                <v-row class="mt-1 ml-5">
                                                    <v-sheet dark rounded height="45" width="45" class='text-center'>
                                                        <div class="mt-2">
                                                            <span>{{item.number}}</span>
                                                        </div>
                                                    </v-sheet>
                                                    <div class="mx-2">
                                                        <p>
                                                            <span class="font-weight-black">{{item.title}}</span> <br>
                                                            <span class="caption font-weight-bold grey--text">{{item.subtitle}}</span>
                                                        </p>
                                                    </div>
                                                </v-row>
                                            </v-list>
                                        </v-row>                                                
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-divider></v-divider>
                            <validation-observer ref="observer" v-slot="{invalid}">
                                <v-col>
                                    <v-col v-if="page===1">
                                        <v-row class="justify-center">
                                            <v-col cols="12" md=6>
                                                <p class="mt-6">
                                                    <span class="stepSize font-weight-black">Etape 1</span> <br>
                                                    <span>Informations relatives au Beat</span>
                                                </p>                                            
                                                <v-row>
                                                            <v-col cols="12" class="position">
                                                                <validation-provider rules="required|titleMax:25" v-slot="{errors}">
                                                                    <v-form @submit.prevent="submit">
                                                                    <span class="caption">Titre</span>
                                                                    <v-text-field :error-messages="errors" solo label="Titre" v-model="drumForm.titre" outlined dense></v-text-field>
                                                                    </v-form>
                                                                </validation-provider>
                                                            </v-col>
                                                    <v-col cols="12" class='position'>
                                                        <v-row>
                                                            <v-col cols="6">
                                                                <validation-provider rules="required" v-slot="{errors}">
                                                                    <v-form @submit.prevent="submit">
                                                                    <span class="caption">Catégorie</span>
                                                                    <v-select :error-messages="errors" label="Sélectionnez une catégorie" solo v-model="drumForm.type" :items="type" outlined dense></v-select>
                                                                    </v-form>
                                                                </validation-provider>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <validation-provider rules="required" v-slot="{errors}">
                                                                    <span class="caption">Gamme utilisée</span>
                                                                    <v-select :error-messages="errors" label="Sélectionnez une Gamme" solo v-model="drumForm.gamme" :items="gamme" outlined dense></v-select>
                                                                </validation-provider>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="12" class='position'>
                                                        
                                                            <span class="caption">Genre musical (Facultatif)</span>
                                                            <v-select solo label="Sélectionnez un genre" :items="genre" v-model="drumForm.genre" outlined dense></v-select>
                                                        
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-row>
                                                            <v-col cols="12" md=6 class='position'>                                                                
                                                                <span class="caption">Instrument (Facultatif)</span>
                                                                <v-select solo  v-model="drumForm.instrument" label="Sélectionnez un instrument" :items="instrument" outlined dense></v-select>                                                                
                                                            </v-col>
                                                            <v-col cols="12" md=6 class='position'>                                                                
                                                                    <v-form @submit.prevent="submit">
                                                                    <span class="caption">BPM </span>
                                                                    <v-text-field  solo v-model="drumForm.bpm" type="number" label="BPM" outlined dense></v-text-field>
                                                                    </v-form>                                                                
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="12" class='position2'>                                                        
                                                                <validation-provider rules="description:1000|min:15" v-slot="{errors}">
                                                                    <v-form @submit.prevent="submit">                                                                        
                                                                        <span class="caption">Description</span>
                                                                        <v-textarea :error-messages="errors" :counter="1000" rows="4" no-resize solo label="Description .." v-model="drumForm.description" outlined ></v-textarea>
                                                                    </v-form>
                                                                </validation-provider>                                                            
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                    </v-col>
                                    <v-col v-if="page===2 && drumForm.type ==='Drumkit'">
                                        <v-row class="justify-center">
                                            <v-col cols='10' md=8>
                                                <v-row>
                                                    <p class="mt-6">
                                                        <span class="stepSize font-weight-black">Etape 2</span> <br>
                                                        <span>Téléchargement du fichier</span>
                                                    </p>
                                                </v-row>
                                            </v-col>
                                            <v-list rounded width="44em">
                                                <v-col cols="12" class="white">
                                                    <v-col class="spacingFiles">
                                                        <validation-provider rules="requiredFile|mp3Size:20000|ext:zip" v-slot="{errors}">
                                                            <span class="font-weight-bold">Fichier audio complet (ZIP)</span> <br>
                                                            <span class="caption">Sélectionnez le fichier audio au format MP3</span>
                                                            <v-file-input :error-messages="errors" accept=".ZIP,.RAR,.7ZIP/*" v-model="mp3Data" outlined dense></v-file-input>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col class="spacingFiles">
                                                        <validation-provider rules="requiredFile|mp3Size:100000|ext:mp3" v-slot="{errors}">
                                                            <span class="font-weight-bold">Fichier démo (MP3)</span> <br>
                                                            <span class="caption">Sélectionnez le fichier démo au format MP3</span>
                                                            <v-file-input :error-messages="errors" accept="audio/*" v-model="mp3DemoData" outlined dense></v-file-input>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col>
                                                        <validation-provider rules="size:2000|ext:png,jpg" v-slot="{errors}">
                                                            <span class="font-weight-bold">Logo</span> <br>
                                                            <span class="caption">Sélectionnez le fichier image (PNG, JPG, etc.)</span>
                                                            <v-file-input :error-messages="errors" required accept="image/*" v-model="imgData" outlined dense></v-file-input>
                                                        </validation-provider>                                                
                                                    </v-col>
                                                </v-col>
                                            </v-list>
											<v-col cols="12">
                                                    <v-row class="justify-center">
                                                        <v-sheet rounded class="d-flex mt-16" width="44em">
                                                            <v-sheet rounded class="orange conditionsSheet text-center">
                                                                <v-icon class="infosIcon" x-large color='white'>mdi-information-outline</v-icon>
                                                            </v-sheet>
                                                            <v-sheet class="infosConditions">
                                                                <v-col cols="12">
                                                                    <v-row class="mt-6 ml-4 mr-1">
                                                                        <span class="conditionsText mb-5">Conditions d'envoi</span>
                                                                        <p>
                                                                            <span>
                                                                                En échange de promouvoir votre contenu, 
                                                                            </span>
                                                                            <span class="font-weight-black brandSize">
                                                                                InstruRap
                                                                            </span>
                                                                            <span>                                                            
                                                                                se réserve le droit de monétiser votre instrumentale 
                                                                                (ou projet audio) sur les chaînes Instrurap et Instrulab via des outils tels qu'AdSence, 
                                                                                YouTube et tout autres services de publicité quels qu'ils soient. Par ailleurs, 
                                                                                si une fois publié, vous souhaitez faire supprimer votre contenu de l'une des chaînes Youtube, 
                                                                                la demande de suppression devra être motivée et une compensation financière pourra alors 
                                                                                vous être demandée.
                                                                            </span>
                                                                        </p>
                                                                        <p>
                                                                            <span>
                                                                                Suite à la mise à jour de Youtube concernant les droits d'auteurs,                                                             
                                                                            </span>
                                                                            <span class="font-weight-black brandSize">
                                                                                InstruRap
                                                                            </span>
                                                                            <span>
                                                                                se réservera désormais l'exclusivité de diffusion. La diffusion de votre projet ne pourra donc se faire uniquement sur les chaînes du groupe InstruRap.
                                                                                En cas de non-respect de cette clause, InstruRap se reserve le droit de supprimer l'oeuvre ou d'en réclammer 
                                                                                un droit d'exploitation auprès de Youtube.
                                                                            </span>
                                                                        </p>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-sheet>
                                                        </v-sheet>
                                                    </v-row>
                                                </v-col>
                                        </v-row>
										<v-col cols="12">
                                            <v-row class='justify-center'>
                                                <v-col cols="12" md=7>
                                                    <v-col cols="12" class="spacingSignature">
                                                        <v-divider class="my-4"></v-divider>
                                                        <validation-provider rules="required" v-slot="{errors}">
                                                            <v-form @submit.prevent="submit">
                                                            <p>
                                                                <span class="bold">Signature électronique</span>
                                                            </p>
                                                            <v-text-field :error-messages="errors" background-color="white" label="Signature électronique" v-model="drumForm.signature" outlined dense>                                                    
                                                            </v-text-field>
                                                            </v-form>
                                                        </validation-provider>
                                                    </v-col>
                                                    <span class="caption ml-3">En signant ce formulaire vous acceptez les : </span>
                                                    <ul>
                                                        <li class="caption">conditions spécifiques d'envoi ci-dessus</li>
                                                        <li class="caption"><a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Administratif%2FCONDITIONS_GENERALES_DUTILISATION_VF_10_12_2020.pdf?alt=media&token=7af8396e-7e91-43c3-a89a-d544e8508c95">conditions d'utilisations du service</a></li>
                                                        <li class="caption"><a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Administratif%2Fcgs.pdf?alt=media&token=0954ab71-1570-484e-86c5-5f01e825452e">conditions de service</a></li>
                                                    </ul> 
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-col>
                                    <v-col v-if="page===2 && drumForm.type==='Sample'">
                                        <v-row class="justify-center">
                                            <v-col cols='10' md=8>
                                                <v-row>
                                                    <p class="mt-6">
                                                        <span class="stepSize font-weight-black">Etape 2</span> <br>
                                                        <span>Téléchargement du fichier</span>
                                                    </p>
                                                </v-row>
                                            </v-col>
                                            <v-list rounded width="44em">
                                                <v-col cols="12" class="white">
                                                    <v-col class="spacingFiles">
                                                        <validation-provider rules="requiredFile|mp3Size:20000|ext:mp3" v-slot="{errors}">
                                                            <span class="font-weight-bold">Fichier audio (MP3)</span> <br>
                                                            <span class="caption">Sélectionnez le fichier audio au format MP3</span>
                                                            <v-file-input :error-messages="errors" accept="audio/*" v-model="mp3Data" outlined dense></v-file-input>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col>
                                                        <validation-provider rules="size:2000|ext:png,jpg" v-slot="{errors}">
                                                            <span class="font-weight-bold">Logo</span> <br>
                                                            <span class="caption">Sélectionnez le fichier image (PNG, JPG, etc.)</span>
                                                            <v-file-input :error-messages="errors" required accept="image/*" v-model="imgData" outlined dense></v-file-input>
                                                        </validation-provider>                                                
                                                    </v-col>
                                                </v-col>
                                            </v-list>
											<v-col cols="12">
                                                    <v-row class="justify-center">
                                                        <v-sheet class="d-flex mt-16" width="44em">
                                                            <v-sheet rounded class="orange conditionsSheet text-center">
                                                                <v-icon class="infosIcon" x-large color='white'>mdi-information-outline</v-icon>
                                                            </v-sheet>
                                                            <v-sheet class="infosConditions">
                                                                <v-col cols="12">
                                                                    <v-row class="mt-6 ml-4 mr-1">
                                                                        <span class="conditionsText mb-5">Conditions d'envoi</span>
                                                                        <p>
                                                                            <span>
                                                                                En échange de promouvoir votre contenu, 
                                                                            </span>
                                                                            <span class="font-weight-black brandSize">
                                                                                InstruRap
                                                                            </span>
                                                                            <span>                                                            
                                                                                se réserve le droit de monétiser votre instrumentale 
                                                                                (ou projet audio) sur les chaînes Instrurap et Instrulab via des outils tels qu'AdSence, 
                                                                                YouTube et tout autres services de publicité quels qu'ils soient. Par ailleurs, 
                                                                                si une fois publié, vous souhaitez faire supprimer votre contenu de l'une des chaînes Youtube, 
                                                                                la demande de suppression devra être motivée et une compensation financière pourra alors 
                                                                                vous être demandée.
                                                                            </span>
                                                                        </p>
                                                                        <p>
                                                                            <span>
                                                                                Suite à la mise à jour de Youtube concernant les droits d'auteurs,                                                             
                                                                            </span>
                                                                            <span class="font-weight-black brandSize">
                                                                                InstruRap
                                                                            </span>
                                                                            <span>
                                                                                se réservera désormais l'exclusivité de diffusion. La diffusion de votre projet ne pourra donc se faire uniquement sur les chaînes du groupe InstruRap.
                                                                                En cas de non-respect de cette clause, InstruRap se reserve le droit de supprimer l'oeuvre ou d'en réclammer 
                                                                                un droit d'exploitation auprès de Youtube.
                                                                            </span>
                                                                        </p>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-sheet>
                                                        </v-sheet>
                                                    </v-row>
                                                </v-col>
                                        </v-row>
										<v-col cols="12">
                                            <v-row class='justify-center'>
                                                <v-col cols="12" md=7>
                                                    <v-col cols="12" class="spacingSignature">
                                                        <v-divider class="my-4"></v-divider>
                                                        <validation-provider rules="required" v-slot="{errors}">
                                                            <v-form @submit.prevent="submit">
                                                            <p>
                                                                <span class="bold">Signature électronique</span>
                                                            </p>
                                                            <v-text-field :error-messages="errors" background-color="white" label="Signature électronique" v-model="drumForm.signature" outlined dense>                                                    
                                                            </v-text-field>
                                                            </v-form>
                                                        </validation-provider>
                                                    </v-col>
                                                    <span class="caption ml-3">En signant ce formulaire vous acceptez les : </span>
                                                    <ul>
                                                        <li class="caption">conditions spécifiques d'envoi ci-dessus</li>
                                                        <li class="caption"><a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Administratif%2FCONDITIONS_GENERALES_DUTILISATION_VF_10_12_2020.pdf?alt=media&token=7af8396e-7e91-43c3-a89a-d544e8508c95">conditions d'utilisations du service</a></li>
                                                        <li class="caption"><a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/instrurap-a48b3.appspot.com/o/Administratif%2Fcgs.pdf?alt=media&token=0954ab71-1570-484e-86c5-5f01e825452e">conditions de service</a></li>
                                                    </ul> 
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-col>                                    
                                    <v-col>
                                        <v-row class="justify-center">
                                            <v-col cols="8">
                                                <v-divider inset class="mr-16 mt-5"></v-divider>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col>
                                        <v-row class="justify-center">
                                            <v-col class="my-5" cols="12">
                                                <v-row class="justify-center">
                                                    <v-col cols=12 md=6>
                                                        <v-row>                                                        
                                                            <v-btn v-if="page===1" disabled>Précédent</v-btn>
                                                            <v-btn v-else @click="page=page-1">Précédent</v-btn>
                                                            <v-spacer></v-spacer>
                                                            <v-btn v-if="page===2 && invalid===false && loading===false" @click="finish()" dark>Soumettre</v-btn>
                                                            <v-btn v-if="page===2 && invalid===false && loading===true" :disabled="loading" :loading="loading" @click="finish()">Soumettre</v-btn>
                                                            <v-btn v-if="page===2 && invalid===true" disabled @click="finish()">Soumettre</v-btn>
                                                            <v-btn v-if="page!=2 && invalid===true" disabled  @click="next()">Suivant</v-btn>
                                                            <v-btn v-if="page!=2 && invalid===false" dark  @click="next()">Suivant</v-btn>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-col>
                            </validation-observer>
                        </v-col>
                    </v-row>
                </v-list>
            </v-col>
        </v-row>
    </v-list>
</div>
</template>
<script>
import VueSimpleAlert from "vue-simple-alert";
import { required, max, min, size, ext } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { db } from "../firebase";
import { storageRef } from "../firebase";

// TODO : Link avec firebase pour avoir le logo du beatmaker si aucun logo selectionné

// Liste des différentes méthodes de validation des cases obligatoires.
extend("required", {
	...required,
	message: "Cette case ne doit pas rester vide",
});
extend("ext", {
	...ext,
	message: "Ce format de fichier n'est pas accepté",
});
extend("requiredFile", {
	...required,
	message: "Fichier manquant",
});
extend("size", {
	...size,
	message: "L'image ne doit pas dépasser 2 MB",
});
extend("mp3Size", {
	...size,
	message: "Le fichier ne doit pas dépasser 20 MB",
});
extend("wavSize", {
	...size,
	message: "Le fichier ne doit pas dépasser 100 MB",
});
extend("max", {
	...max,
	message: "Le nom ne doit pas dépasser 16 caractères",
});
extend("titleMax", {
	...max,
	message: "Le nom ne doit pas dépasser 25 caractères",
});
extend("min", {
	...min,
	message: "La description doit faire au minimum 15 lettres",
});
extend("description", {
	...max,
	message: "La description ne doit pas dépasser 1000 lettres",
});

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
	},
	created() {
		(this.drumForm.name = this.artistName),
			(this.drumForm.email = this.artistMail);
	},
	methods: {
		// Permet d'upload le MP3 dans un dossier du cloud, puis d'ajouter le lien au cloud dans la base de données

		async mp3Upload() {
			const mp3FileRef = storageRef.child(
				"Drumkit et samples/" +
					this.drumForm.artist +
					"/" +
					this.drumForm.titre +
					"/" +
					this.drumForm.titre +
					" " +
					"mp3"
			);
			await mp3FileRef.put(this.mp3Data);
			this.drumForm.beatMP3 = await mp3FileRef.getDownloadURL();
			console.log(this.drumForm.beatMP3);
		},
		async mp3DemoUpload() {
			const mp3FileRef = storageRef.child(
				"Drumkit et samples/" +
					this.drumForm.artist +
					"/" +
					this.drumForm.titre +
					"/" +
					this.drumForm.titre +
					" " +
					"mp3"
			);
			await mp3FileRef.put(this.mp3DemoData);
			this.drumForm.beatMP3Demo = await mp3FileRef.getDownloadURL();
			console.log(this.drumForm.beatMP3Demo);
		},
		// Permet d'upload l'image dans un dossier du cloud, puis d'ajouter le lien au cloud dans la base de données
		async imageUpload() {
			const imageFileRef = storageRef.child(
				"Drumkit et samples/" +
					this.drumForm.artist +
					"/" +
					this.drumForm.titre +
					"/" +
					this.drumForm.titre +
					" " +
					"image"
			);
			await imageFileRef.put(this.imageData);
			this.drumForm.imgPath = await imageFileRef.getDownloadURL();
			console.log(this.drumForm.imgPath);
		},
		// Ferme le formulaire (sans envoyer les inputs)
		abort() {
			setTimeout(() => this.$emit("done"), 200);
		},
		finish() {
			// Transition du bouton
			this.loading = true;
			// Upload des fichiers et création dans la base de données
			this.imageUpload();
			this.mp3DemoUpload();
			this.mp3Upload().then(() => {
				const project = {
					artist: this.drumForm.name,
					mail: this.drumForm.email,
					title: this.drumForm.titre,
					type: this.drumForm.type,
					description: this.drumForm.description,
					bpm: Number(this.drumForm.bpm),
					genre: this.drumForm.genre,
					instrument: this.drumForm.instrument,
					key: this.drumForm.gamme,
					mp3: this.drumForm.beatMP3,
					mp3Demo: this.drumForm.beatMP3Demo,
					pochette: this.drumForm.imgPath,
				};
				db.collection("songs")
					.add(project)
					.then(() => {
						this.$emit("done");
					});
				// Arrête la transition du bouton et lance le dialog
				this.loading = false;
				VueSimpleAlert.alert(
					"Ton sample a bien été mis en ligne!",
					"Félicitations",
					"success"
				);
			});
		},
		// Change de page
		next() {
			this.page = this.page + 1;
		},
	},
	props: {
		artistName: String,
		artistMail: String,
	},
	data() {
		return {
			loading: false,
			imgData: null,
			mp3Data: null,
			mp3DemoData: null,
			page: 1,
			sample: "Non",
			accounts: [
				"Kaowbeat",
				"GreenWojack",
				"Pylot",
				"Machin",
				"Stuff",
				"Neobeats",
				"RedWojack",
				"Pilote",
				"Truc",
			],
			type: ["Sample", "Drumkit"],
			genre: [
				"Tous les genres",
				"Rap",
				"Trap",
				"Drill",
				"Club",
				"Cloud",
				"Lofi",
				"Reggaeton",
				"R&B/Soul",
				"Dancehall",
				"Grime",
				"Funk",
				"2Step",
				"Pop",
				"Reggae",
				"Rai",
				"Electro",
				"Rock",
				"Autre",
			],
			gamme: [
				"Am",
				"A#m",
				"A Maj",
				"A# Maj",
				"Bm",
				"B#m",
				"B Maj",
				"B# Maj",
				"Cm",
				"C#m",
				"C Maj",
				"C# Maj",
				"Dm",
				"D#m",
				"D Maj",
				"D# Maj",
				"Em",
				"E#m",
				"E Maj",
				"E# Maj",
				"Fm",
				"F#m",
				"F Maj",
				"F# Maj",
				"Gm",
				"G#m",
				"G Maj",
				"G# Maj",
			],
			instrument: [
				"Piano",
				"Guitare",
				"Violon",
				"Flute",
				"Synthé",
				"Voix",
				"Saxo",
				"Tambour",
				"Trompette",
			],
			drumForm: {
				type: "",
				name: "",
				mail: "",
				path: "",
				description: "",
				gamme: "",
				signature: "",
				genre: "",
				titre: "",
				instrument: "",
				bpm: 0,
				imgPath: [],
				beatMP3: [],
				beatMP3Demo: [],
			},
			user: {
				username: "WojackHorseman",
				mail: "WojackHorseman@mail.com",
			},
			steps: [
				{
					number: 1,
					title: "Beat",
					subtitle: "Informations projet",
				},
				{
					number: 2,
					title: "Upload",
					subtitle: "Ficher et image",
				},
			],
		};
	},
};
</script>
<style >
.titre {
	font-size: 230%;
}

/* Styling des cases étapes du formulaire */
.stepSize {
	font-size: 135%;
}
.list {
	margin-top: 9em;
}
.form {
	transform: translateY(-6.5em);
}

/* Positionnement des différentes fenetres d'input page 1 et 2 */
.position {
	height: 5em;
}
.position2 {
	height: 10em;
}
.positionTitre {
	height: 5em;
}
@media (max-width: 430px) {
	.positionSm {
		margin-top: 1.5em;
	}
}
</style>