<template lang="">
<div class='box' :class="screenColor">
<div class="mt-5" v-if="drumForm===false && instruForm===false">
    <v-row  class="justify-center d-flex flex-row">
      <v-list :width="instruWidth" class="d-flex flex-row">
        <transition name="fade">
          <v-col cols="12" v-if="publishForm === false">
            <v-hover class="red" v-slot="{ hover }">
              <v-list class='d-flex flex-row instru' height="600" :elevation="hover ? 0 : 12">
                <v-list-item class='justify-center instru mx-2' >
                  <v-row class="justify-end text-end">
                    <v-col cols="12" class="text-center justify-center">
                      <div class="titreInstru white--text">
                        <span>Publier une</span> <br>
                        <span>INSTRUMENTAL</span>
                      </div>
                      <div class="infosInstru">
                        <p class="hintText1">
                          Suivez ce formulaire pour publier gratuitement votre production musicale originale sur InstruRap.fr.
                        </p>
                      </div>
                    </v-col>
                        <v-row class="checkbox">
                          <v-col cols="12">
                            <v-checkbox
                              v-model="checked"
                              value="success"
                              hide-details
                              dark
							  @click="submission()"
                              class="d-flex"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" class="d-flex flex-row checkboxText ">
                            <span class="d-flex white--text">Suggérer ma prod' à la sélection InstruRap</span> <br>
                          </v-col>
                        </v-row>
                        <v-col cols="12" class="checkboxCaption">
                          <span class="white--text">
                            Envoyer mon instrumentale pour apparaître sur l'une de nos chaînes YouTube, sous réserve de validation.
                          </span>
                        </v-col>
                      <v-btn absolute bottom right @click="instruNext()">
                        <span class="mx-2">Etape suivante</span>
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-hover>
          </v-col>
        </transition>
      </v-list>
      <v-list :width="drumWidth" class="d green-flex flex-row">
        <transition name="drum">
          <v-col cols="12" v-if='(publishForm === false)'>
            <v-hover v-slot="{ hover }">
              <v-list class='d-flex flex-row' style="background:#37b037" height="600" :elevation="hover ? 0 : 12">
                <v-list-item class='justify-center drum mx-2' >
                  <v-row class="justify-end text-end">
                    <v-col cols="12" class="text-center justify-center">
                      <div class="titreDrum white--text">Publier un DRUMKIT/SAMPLE</div>
                      <div class="infosDrum">
                        <p class="drum hintText">
                          Suivez ce formulaire pour publier gratuitement votre contenu personnel et autres ressources beatmaker sur Instrurap.fr.
                        </p>
                      </div>
                    </v-col>
                    <v-btn absolute bottom right @click="drumNext()">
                      <span class="mx-2">Etape suivante</span>
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-hover>
          </v-col>
        </transition>
      </v-list>
    </v-row>
  </div>
  <transition name="test" origin="bottom center 0">
    <div v-if="instruForm===true">
      <InstruForm @done="finish" :checked="select" :artistName="this.user.name" :artistMail="this.user.mail"/>
    </div>
    <div v-if="drumForm===true">
      <DrumForm @done="finish" :artistName="this.user.name" :artistMail="this.user.mail"/>
    </div>
  </transition>
</div>
</template>

<script>
import InstruForm from "../components/InstruForm.vue";
import DrumForm from "../components/DrumForm.vue";

export default {
	components: {
		InstruForm,
		DrumForm,
	},
	methods: {
		submission() {
			if (this.checked === "success") {
				this.select = true;
				console.log(this.select);
			}
			if (this.checked === null) {
				this.select = false;
				console.log(this.select);
			}
		},
		// Active les transitions une fois le formulaire rempli ou annulé
		finish() {
			setTimeout(() => (this.instruForm = false), 100);
			setTimeout(() => (this.drumForm = false), 100);
			setTimeout(() => (this.publishForm = false), 500);
		},
		// Cette fonction lance la transition des deux premieres fenetres, puis, après un délai, lance la transition du formulaire DRUM
		drumNext() {
			this.publishForm = true;
			setTimeout(() => (this.drumForm = true), 600);
		},
		// Cette fonction lance la transition des deux premieres fenetres, puis, après un délai, lance la transition du formulaire INSTRU
		instruNext() {
			this.publishForm = true;
			setTimeout(() => (this.instruForm = true), 600);
		},
	},

	computed: {
		screenColor() {
			switch (this.drumForm || this.instruForm) {
				case false:
					return "white";
				case true:
					return "grey lighten-1";
				default:
					return "white";
			}
		},
		instruWidth() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "90%";
				case "sm":
					return "90%";
				case "md":
					return "90%";
				case "lg":
					return "50%";
				case "lg":
					return "40%";
				default:
					return "40%";
			}
		},
		drumWidth() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "90%";
				case "sm":
					return "90%";
				case "md":
					return "90%";
				case "lg":
					return "30%";
				case "xl":
					return "25%";
				default:
					return "25%";
			}
		},
	},
	data() {
		return {
			select: "",
			publishForm: false,
			checked: "",
			drumForm: false,
			e1: 1,
			instruForm: false,
			user: {
				name: "WojackHorseman",
				mail: "WojackHorseman@mail.com",
			},
		};
	},
};
</script>
<style>
/* Transitions des 2 formulaires */
.test-enter-active,
.test-leave-active {
	transition: all 0.7s ease;
	opacity: 0.9;
}
.test-enter,
.test-leave-to {
	transition: all 1s ease;
	transform: translateY(70em);
	opacity: 0.5;
}

/* Transitions et styling des deux premieres fenetres */
.drum-enter-active,
.drum-leave-active {
	transition: all 0.7s ease;
	opacity: 0.5;
}
.drum-enter,
.drum-leave-to {
	transition: all 1s ease;
	transform: translateX(70em);
	opacity: 0.5;
}
.fade-leave-active,
.fade-enter-active {
	transition: all 0.7s ease;
	opacity: 0.5;
}
.fade-enter,
.fade-leave-to {
	transform: translateX(-70em);
	transition: all 1s ease;
	opacity: 0.5;
}
.instru:hover {
	background: #ef5350;
}
.drum:hover {
	background: #4eba4e;
}

/* Styling du texte d'information */
.hintText1 {
	font-size: 110%;
	color: #ffd9d9;
}
.hintText {
	font-size: 110%;
	color: #bdffbd;
}
.infosInstru {
	transform: translate(0em, 7.5rem);
	text-align: start;
}
.infosDrum {
	transform: translate(0em, 6rem);
	text-align: start;
}
@media (max-width: 640px) {
	.infosInstru {
		transform: translate(0em, 4rem);
	}
}

/* Styling de la checkbox et du texte */
.checkbox {
	transform: translate(0rem, 7rem);
	text-align: start;
}
.checkboxText {
	transform: translate(1.5rem, -3rem);
	text-align: start;
}
.checkboxCaption {
	font-size: 73%;
	transform: translate(0.8rem, 3.5rem);
	text-align: start;
}
@media (max-width: 640px) {
	.checkbox {
		transform: translate(0.5rem, 3rem);
	}
	.checkboxCaption {
		font-size: 73%;
		transform: translate(0rem, 1rem);
	}
}
@media (min-width: 640px) {
	.checkbox {
		transform: translate(0.5rem, 6rem);
	}
	.checkboxCaption {
		font-size: 70%;
		transform: translate(1.3rem, 2rem);
	}
}
/* Styling des titres */
.titreInstru {
	transform: translate(0rem, -4rem);
	font-size: 185%;
}
.titreDrum {
	transform: translate(0rem, -5rem);
	font-size: 180%;
}
html,
body {
	margin: 0px;
	height: 100%;
}

.box {
	height: 100%;
}
</style>