<template lang="">
<v-container class='grey darken-1'>
  <v-row class="justify-center">
    <v-col cols="12" md=10>
      <v-row class="justify-center">
        <v-col class="grey darken-4 py-2 px-4 mx-4 my-5 rounded mt-1" cols="11" sm=5 md=5 lg=3 v-for="song in songItem" :key="song.songID">
          <v-row class="px-1 py-0">
            <v-col cols="4" sm=3 class="px-0">
				<v-row dense class="py-1 px-1">
              <v-img :src="song.imagePath" height="100%" class="align-end rounded-lg">
              </v-img>
				</v-row>
            </v-col>
            <v-col cols="8" sm=9 >
				<v-row dense class='fill-height mt-1 align-content-space-between'>

                <v-col cols="11" sm="10">
					<v-row class='mb-0'>						
                  <p class='my-0 ml-2 mt-2 songtitle'>
                    <span class='mr-1 white--text text-uppercase '>{{song.title}}</span>
                    <span class="grey--text ">{{song.type}}</span> <br>
                    <span class='mr-1 white--text text-uppercase '>{{song.beatmaker}}</span>
                  </p>
					</v-row>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="1" sm="2"> 
                  <v-row class="justify-end">
                    <v-btn small icon class="mt-1">
                      <v-icon color='white'>mdi-information-outline</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>

                <v-col cols="4"  class="py-0">
                  <v-btn x-small icon @click.stop.prevent="like(song.songID), song.likes++" v-if="user.liked.indexOf(song.songID) === -1">
                    <v-icon class="white--text">mdi-thumb-up</v-icon>
                    <span class="mr-3 mt-1 white--text">{{ song.likes }}</span>
                  </v-btn>
                  <v-btn x-small icon @click.stop.prevent=" removeLike(song.songID), song.likes--" v-else>
                    <v-icon class="white--text" color="green">mdi-thumb-up</v-icon>
                    <span class="mr-3 mt-1 white--text caption">{{song.likes}}</span>
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
				<v-col cols="8">
                  <v-row class='justify-end'>
                      <p class="mb-1 mx-2 px-3 py-1 rounded green">
                        <span class="free font-weight-bold white--text">{{song.price}}</span>
                      </p>
                      <v-btn class="mt-2" small icon><v-icon color='white'>mdi-download</v-icon></v-btn>
                  </v-row>
				</v-col>

				</v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
	  <v-col cols="12">
      <v-row class='justify-center'>
        <v-col cols="12" sm=11 lg=10>
          <v-row :class='justifyPage'>
			  <v-btn v-if="this.page>0" small dark @click="prevPage" class='mt-3 h1'>
              <span class='grey--text text--lighten-2 next'>< Page Précédente </span>
            </v-btn>
            <v-btn small text @click="nextPage" class='mt-3 h1'>
              <span class='grey--text text--lighten-2 next'>Page Suivante ></span>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
	  </v-col>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
// TODO : link data

export default {
	data: () => ({
		user: {
			id: ["id"],
			liked: ["657417"],
		},
		page:0,
		// toggle le lecteur
		audioPlayer: false,
		songItem: [
			{
				songID: "657418",
				title: "It was only a dream test",
				beatmaker: "Pylot",
				type: "Drumkit",
				imagePath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
				songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
				beatmakerPath: "http://iampylot.com/",
				isPlaying: false,
				likes: 1,
				BPM: 130,
				genre: "Rap",
				mood: "Dark",
				price: "Free",
				instrument: "Piano",
				licence: "Licence non-commerciale",
			},
      {
				songID: "657420",
				title: "It was only a dream test",
				beatmaker: "Pylot",
				type: "Drumkit",
				imagePath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
				songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
				beatmakerPath: "http://iampylot.com/",
				isPlaying: false,
				likes: 1,
				BPM: 130,
				genre: "Rap",
				mood: "Dark",
				price: "Free",
				instrument: "Piano",
				licence: "Licence non-commerciale",
			},
			{
				songID: "657417",
				title: "dream",
				beatmaker: "Pylot",
				type: "Sample",
				imagePath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
				songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
				beatmakerPath: "http://iampylot.com/",
				isPlaying: false,
				likes: 1,
				BPM: 130,
				genre: "Rap",
				mood: "Dark",
				price: "Free",
				instrument: "Piano",
				licence: "Licence non-commerciale",
			},
      {
				songID: "657414",
				title: "It was only a dream test",
				beatmaker: "Pylot",
				type: "Drumkit",
				imagePath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
				songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
				beatmakerPath: "http://iampylot.com/",
				isPlaying: false,
				likes: 1,
				BPM: 130,
				genre: "Rap",
				mood: "Dark",
				price: "Free",
				instrument: "Piano",
				licence: "Licence non-commerciale",
			},
      {
				songID: "657410",
				title: "It was only a dream test",
				beatmaker: "Pylot",
				type: "Drumkit",
				imagePath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
				songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
				beatmakerPath: "http://iampylot.com/",
				isPlaying: false,
				likes: 1,
				BPM: 130,
				genre: "Rap",
				mood: "Dark",
				price: "Free",
				instrument: "Piano",
				licence: "Licence non-commerciale",
			},
			{
				songID: "657421",
				title: "dream",
				beatmaker: "Pylot",
				type: "Sample",
				imagePath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
				songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
				beatmakerPath: "http://iampylot.com/",
				isPlaying: false,
				likes: 1,
				BPM: 130,
				genre: "Rap",
				mood: "Dark",
				price: "Free",
				instrument: "Piano",
				licence: "Licence non-commerciale",
			},
		],
	}),
	computed:{
		justifyPage(){
			switch (this.page) {
				case 0:
					return "justify-end";
				case 1:
					return "justify-space-between";			
				default:
					return "justify-space-between";
			}
		}
	},
	methods: {
		nextPage(){
			this.page = this.page +1;
		},
		prevPage(){
			this.page = this.page -1;
		},
		//   Permet de liker le son
		like(song) {
			this.user.liked.push(song);
		},
		// Permet de retirer le like d'un son
		removeLike(song) {
			this.user.liked.splice(this.user.liked.indexOf(song), 1);
		},
		// Redirige sur la page du son
		info() {
			return console.log("marche");
		},
	},
};
</script>
<style>
.songtitle{
	font-size: 0.6vw;
}
.free {
	user-select: none;
}
@media (max-width: 600px) {

	.songtitle{
		font-size: 2.5vw;
	}
}
@media (min-width: 600px) {

	.songtitle{
		font-size: 1.1vw;
	}
	.next{
  font-size: 150%;
}
}
@media (min-width: 960px) {

	.songtitle{
		font-size: 0.85vw;
	}
}
@media (min-width: 1264px) {

	.songtitle{
		font-size: 0.6vw;
	}
}
</style>