
import { firestorePlugin } from "vuefire";
import Vue from "vue";
import VueHorizontal from "vue-horizontal";
import App from "./App.vue";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import VueSimpleAlert from "vue-simple-alert";
import { ValidationProvider, extend } from "vee-validate";
import router from './router'

require('@/assets/Style/main.css')

Vue.config.productionTip = false;
Vue.use(firestorePlugin, VueHorizontal, VueSimpleAlert,ValidationProvider,extend);

new Vue({
  vuetify,
  router,
  render: (h) => h(App)
}).$mount('#app');
