<template>
  <div>
    <v-row class="justify-center">
      <v-col cols="11" class="px-0">
        <h1 class="mt-4 h1">Tableau de bord</h1>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12" sm="4" class="pa-4">
          <v-card height="100%" width="97%" class="counter-card">
            <v-card height="101%" width="1%" class="info"></v-card>
            <v-row class="justify-space-between">
              <v-card-title>
                <h2 class="ml-2">
                  <span class="info--text cardHeader">Projets envoyés</span><br><span>{{nbSubmissions}}</span>
                </h2>
              </v-card-title>
            <v-icon color='grey lighten-1' size="72px" class="mb-2 mr-2">mdi-export-variant</v-icon>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" class="pa-4">
          <v-card height="100%" width="97%" class="counter-card">
            <v-card height="101%" width="1%" class="error"></v-card>
            <v-row class="justify-space-between">
           <v-card-title>
              <h2 class="ml-2">
                <span class="error--text cardHeader">Projets en attente</span><br><span>{{nbPending}}</span>  
              </h2>
            </v-card-title>
            <v-icon size="72px" color='grey lighten-1' class="mb-2 mr-2">mdi-pause</v-icon>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" class="pa-4">
          <v-card height="100%" width="97%" class="counter-card">
            <v-card height="101%" width="1%" class="success"></v-card>
              <v-row class="justify-space-between">
                <v-card-title>
                  <h2 class="ml-2">
                    <span class="success--text cardHeader">Projets validés</span><br><span>{{nbApproved}}</span>
                  </h2>
                </v-card-title>
              </v-row>
            <v-icon color='grey lighten-1' size="72px" class="mb-2 mr-2">mdi-youtube</v-icon>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-row class="justify-center mt-1">
      <v-col cols="11" md=3>
      <v-card width="100%" class="px-8" height="17em">
          <p class="text-center mt-4 py-5 px-5"><span class="publishHeader">Inspiré(e) ?</span><br>
          <span>Vous êtes inspiré(e) et prêt(e) à nous envoyer un </span><br><span>projet musical, un bon Beat ? Oui ?</span>
           <br> <span>Dans ce cas, cliquez sur le bouton ci-dessous pour accéder au formulaire d'envoi.</span></p>
           <v-row class="justify-center"><v-btn color='info'>Envoyer un beat</v-btn></v-row>
      </v-card>
      </v-col>
      <v-col cols="11" md=8>
        <v-card width="98%" class="ma-4">
          <v-card-title>
            <h2>Mes demandes de publication</h2>
            </v-card-title>
          <v-simple-table>
            <template>
              <thead>
                <tr>
                  <th>Nom de l'instrumentale</th>
                  <th>File d'attente</th>
                  <th>État</th>
                  <th>Chaîne</th>
                  <th>Dernière mise à jour</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="song in submissions" :key="song.songID" :class="song.submission.status">
                  <td>{{ song.title }}</td>
                  <td>{{ queuePosition(song.submission.submissionNumber) }}</td>
                  <td>{{ statusFR(song.submission.status) }}</td>
                  <td>{{ song.submission.channel }}</td>
                  <td>{{ formatDate(song.submission.lastUpdate) }}</td>
                  <submission-details :song="song">
                    <template v-slot:button="{on}">
                      <div v-on="on" class="popup-activator"></div>
                    </template>
                  </submission-details>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SubmissionDetails from "../components/SubmissionDetails.vue"

export default {
  components:{
    SubmissionDetails,
  },
  data() {
    return {
      //TODO: Fetch submissions from back
      submissions: [
        {
          songID: "657417",
          title: "It was only a dream",
          beatmaker: "Pylot",
          imagePath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
          songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
          beatmakerPath: "http://iampylot.com/",
          beatmakerMail: "example@gmail.com",
          samples: true,
          isPlaying: false,
          nbListens: 111,
          nbDownload: 3,
          likes: 1,
          BPM: 130,
          genre: "Rap",
          mood: "Dark",
          instrument: "Piano",
          licence: "Licence non-commerciale",
          downloadLinks: {
            mp3: "",
            wav: "",
            tagged: "",
          },
          comments: [],
          isSubmission: true,
          submission: {
            comments: [
              {
                author: "Toto77",
                text: "Trop bien ton son",
              },
              {
                author: "BeatCritic9000",
                text: "Mouais j'ai entendu mieux",
              },
              {
                author: "MecPaumé99",
                text: "Je sais pas ce que je fais ici :/ Du coup je vais laisser un commentaire super long pour qu'on puisse voir ce que ça donne. Apparement c'est toujours pas assez long. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi non quis exercitationem culpa nesciunt nihil aut nostrum explicabo reprehenderit optio amet ab temporibus asperiores quasi cupiditate. Voluptatum ducimus voluptates voluptas?",
              },
            ],
            status: 'pending',
            channel: "InstruLab",
            lastUpdate: "2020-06-08T22:33:44Z",
            submissionNumber: 6,
          },
        },
        {
          songID: "657419",
          title: "It was only a dream",
          beatmaker: "Pylot",
          imagePath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
          songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
          beatmakerPath: "http://iampylot.com/",
          beatmakerMail: "example@gmail.com",
          samples: true,
          isPlaying: false,
          nbListens: 111,
          nbDownload: 3,
          likes: 1,
          BPM: 130,
          genre: "Rap",
          mood: "Dark",
          instrument: "Piano",
          licence: "Licence non-commerciale",
          downloadLinks: {
            mp3: "",
            wav: "",
            tagged: "",
          },
          comments: [],
          isSubmission: true,
          submission: {
            comments: [
              {
                author: "Toto77",
                text: "Trop bien ton son",
              },
              {
                author: "BeatCritic9000",
                text: "Mouais j'ai entendu mieux",
              },
              {
                author: "MecPaumé99",
                text: "Je sais pas ce que je fais ici :/ Du coup je vais laisser un commentaire super long pour qu'on puisse voir ce que ça donne. Apparement c'est toujours pas assez long. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi non quis exercitationem culpa nesciunt nihil aut nostrum explicabo reprehenderit optio amet ab temporibus asperiores quasi cupiditate. Voluptatum ducimus voluptates voluptas?",
              },
            ],
            status: 'approved',
            channel: "InstruRap",
            lastUpdate: "2021-08-21T13:56:00Z",
            submissionNumber: 10,
          },
        },
        {
          songID: "657418",
          title: "It was only a dream",
          beatmaker: "Pylot",
          imagePath: "https://ukf.com/wp-content/uploads/2015/04/pylot.jpg",
          songPath: "https://www.youtube.com/watch?v=5AKxVJlCA4M",
          beatmakerPath: "http://iampylot.com/",
          beatmakerMail: "example@gmail.com",
          samples: true,
          isPlaying: false,
          nbListens: 111,
          nbDownload: 3,
          likes: 1,
          BPM: 130,
          genre: "Rap",
          mood: "Dark",
          instrument: "Piano",
          licence: "Licence non-commerciale",
          downloadLinks: {
            mp3: "",
            wav: "",
            tagged: "",
          },
          comments: [],
          isSubmission: true,
          submission: {
            comments: [
              {
                author: "Toto77",
                text: "Trop bien ton son",
              },
              {
                author: "BeatCritic9000",
                text: "Mouais j'ai entendu mieux",
              },
              {
                author: "MecPaumé99",
                text: "Je sais pas ce que je fais ici :/ Du coup je vais laisser un commentaire super long pour qu'on puisse voir ce que ça donne. Apparement c'est toujours pas assez long. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi non quis exercitationem culpa nesciunt nihil aut nostrum explicabo reprehenderit optio amet ab temporibus asperiores quasi cupiditate. Voluptatum ducimus voluptates voluptas?",
              },
            ],
            status: 'approved',
            channel: "InstruRap",
            lastUpdate: "2021-08-21T13:56:00Z",
            submissionNumber: 10,
          },
        },
      ],
    };
  },
  computed: {
    nbSubmissions() {
      return this.submissions.length;
    },
    nbPending() {
      return this.submissions.reduce((accumulator, song) => {
        if (song.submission.status === "pending"){
          accumulator++
        }
        return accumulator
      }, 0)
    },
    nbApproved() {
      return this.submissions.reduce((accumulator, song) => {
        if (song.submission.status === "approved") {
          accumulator++
        }
        return accumulator
      }, 0)
    },
  },
  methods: {
    //TODO: Calculate queue position from submission number
    queuePosition(submissionNumber) {
      return submissionNumber;
    },
    formatDate(timestamp) {
      const date = new Date(timestamp)
      return date.toLocaleDateString('fr-FR', {})
    },
    statusFR(status) {
      switch (status) {
        case "pending":
          return "En attente";
        case "rejected":
          return "Rejeté";
        case "approved":
          return "Accepté";
        default:
          return "";
      }
    },
  },
}
</script>

<style scoped>
h1 {
  font-size: 2rem;
}
.publishHeader{
font-size: 140%;
}
.publishText{
  font-size: 110%;
}
.counter-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 7em;
}
.v-card__title h2{
  word-break: normal;
  font-size: 90%;
}

.pending td {
  color: orange;
}

.rejected td {
  color: #ff5252;
}

.approved td {
  color: #4caf50;
}

.popup-activator {
  width: 100%;
  height: 48px;
  position: absolute;
  left: 0px;
  z-index: 100;
}

.popup-activator:hover {
  cursor: pointer;
}
</style>
