<template lang="">
<Setting :name="user.name" :instagram="user.instagram" :snapchat="user.snapchat" :facebook="user.facebook" :youtube="user.youtube" :internet="user.internet" :altName="user.altName" :twitter="user.twitter" :password="user.password" :bio="user.bio" />
</template>
<script>
import Setting from "../components/Setting.vue";
export default {
    components:{
        Setting,
    },
    data() {
        return {
            user:{
                name:"Green Wojack",
                altName:"GreenWojack",
                instagram:"",
                snapchat:"",
                facebook:"",
                youtube:"",
                internet:"",
                twitter:"/GreenWojack",
                password:"Couilles",
                bio: "Voila, c'est ma bio, elle est plutôt cool à lire nan ? "
            }
        }
    },
}
</script>
<style lang="">
    
</style>