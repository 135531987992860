<template lang="">
<div>
  <SearchSampleDrum class="mt-10 mb-3"/>
</div>
</template>
<script>
import SearchSampleDrum from "../components/SearchSampleDrum.vue";

export default {
	components: {
		SearchSampleDrum,
	},
};
</script>
<style lang="">
</style>